@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@login-cls: ~'@{ra-prefix}-login';
@login-form-cls: ~'@{login-cls}-form';
@login-form-title-cls: ~'@{login-form-cls}-title';
@login-form-footer-cls: ~'@{login-cls}-form-footer';
@login-form-footer-center-cls: ~'@{login-form-footer-cls}-center';

:export {
  loginCls: @login-cls;
  loginFormCls: @login-form-cls;
  loginFormFooterCls: @login-form-footer-cls;
  loginFormFooterCenterCls: @login-form-footer-center-cls;
  loginFormTitleCls: @login-form-title-cls;
}

.@{login-cls} {
  width: 100%;
  max-width: 330px;
  padding: 5px;
  margin: 0 auto;

  .@{login-form-cls} {
    max-height: 700px;
  }

  .@{login-form-footer-cls} {
    display: flex;
    justify-content: space-between;

    &.@{login-form-footer-center-cls} {
      justify-content: center;
    }
  }

  .@{login-form-title-cls} {
    margin-bottom: 20px;
  }
}
